<template>
  <div class="page">
    <div class="heading">
      <v-toolbar dense :flat="flatapp">
        <v-btn icon @click="menulink">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-breadcrumbs
          :items="crumbs"
          style="padding: 14px 20px"
          class="capitalize"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" href="https://www.umohe.com" target="_blank">
              <v-icon size="16" color="primary">fa-home</v-icon>
            </v-btn>
          </template>
          <span>Go home</span>
        </v-tooltip>
      </v-toolbar>
      <v-divider></v-divider>
    </div>

    <div class="body">
      <v-data-iterator
        :items="dependencyList"
        item-key="name"
        :items-per-page="8"
        :loading="loading"
      >
        <template v-slot:default="{ items }">
          <v-row>
            <v-col
              v-for="item in items"
              :key="item.name"
              cols="12"
              sm="12"
              md="4"
              lg="4"
            >
              <v-card flat>
                <v-overlay absolute :value="switching"></v-overlay>
                <v-img
                  :src="item.pix"
                  :aspect-ratio="16 / 9"
                  max-height="210"
                ></v-img>

                <v-card-title style="color: rgb(0, 100, 0)">
                  {{ item.salute }} {{ item.first_name }} {{ item.mid_name }}
                  {{ item.last_name }}
                </v-card-title>

                <v-divider></v-divider>
                <v-list dense flat>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Relation</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-content style="justify-content: end">
                      {{ item.relation }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Gender</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-content style="justify-content: end">
                      {{ item.gender }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Birth date</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-content style="justify-content: end">
                      {{ item.date_of_birth | myDate }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Next Appointment</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-content style="justify-content: end">
                      {{ item.appointment | myDating }}
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="primary"
                        small
                        text
                        v-on="on"
                        @click="switchAcc(item.my_subscriptions_id)"
                      >
                        switch to this account
                      </v-btn>
                    </template>
                    <span>
                      Switch from {{ myAccount | capitalize }}'s account to this
                      account. Can always switch back
                    </span>
                  </v-tooltip>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </div>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import WinScroll from "../../mixins/windowscroll";
export default {
  components: {},
  mixins: [WinScroll("position")],
  data() {
    return {
      crumbs: [
        {
          exact: true,
          link: true,
          text: "dashboard",
          to: { name: "patient.dash" },
        },
        {
          exact: true,
          link: true,
          text: "dependencies",
          to: { name: "patient.dependents" },
        },
      ],
      loading: false,
      dependencyList: [],
      myAccount: null,
      switching: false,
      isDependancy: null,
    };
  },
  computed: {
    flatapp: function () {
      if (this.position[1] > 30) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.myDepdendancy();
    this.myAccount = localStorage.getItem("user.name");
    this.myAccount = localStorage.getItem("user.hasDependancy");
  },
  methods: {
    myDepdendancy() {
      let me = localStorage.getItem("user.hasDependancy");
      this.isDependancy = me;
      console.log("me", me);
      if (me) {
        this.list();
      } else {
        console.log("me", me);
      }
    },
    menulink() {
      this.$emit("sidebar");
    },
    list() {
      this.loading = true;
      let self = this;
      let hasDep = localStorage.getItem("user.hasDependancy");
      Restful.medics.patient.dependancy
        .dependancy(hasDep)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.dependencyList = response.data;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
          self.loading = false;
        });
    },
    switchAcc(a) {
      this.switching = true;
      console.log("a", a);
      let my_subscriptions_id = parseInt(a);
      let hasDependancy = localStorage.getItem("user.hasDependancy");
      let data = { my_subscriptions_id, hasDependancy };

      let self = this;
      Restful.medics.patient.dependancy
        .swithchAcc(data)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            console.log("good");
            let name =
              response.data.salutation +
              " " +
              response.data.first_name +
              " " +
              response.data.last_name;
            let role = response.data.role;
            let subTypeId = response.data.subscriber_type;
            let subscription = response.data.subscriptions;
            let pix = response.data.pix;
            let token = response.data.token;
            let busines = response.data.busines;
            let dependency = response.data.dependency;
            let hasDependancy = response.data.hasDependancy;

            // console.log("name", name);
            // console.log("role", role);
            // console.log("subTypeId", subTypeId);
            // console.log("subscription", subscription);
            // console.log("pix", pix);
            // console.log("token", token);
            // console.log("busines", busines);
            // console.log("dependency", dependency);
            // console.log("hasDependancy", hasDependancy);
            // localstorage
            localStorage.setItem("user.name", name);
            localStorage.setItem("user.subscriber_type", subTypeId);
            localStorage.setItem(
              "user.subscription",
              JSON.stringify(subscription)
            );
            localStorage.setItem("user.role", role);
            localStorage.setItem("user.pix", pix);
            localStorage.setItem("user.token", token);
            localStorage.setItem("user.busines", busines);
            localStorage.setItem("user.dependency", dependency);
            localStorage.setItem("user.hasDependancy", hasDependancy);
            this.primaryAcc();
            this.$router.go(0);
          }
          this.switching = false;
        })
        .catch((error) => {
          console.log(error);
          self.snack.bar = true;
          self.snack.color = "error";
          self.snack.text = error.response.data;
          self.switching = false;
        });
    },
    primaryAcc() {
      let token = localStorage.getItem("user.token");
      console.log("a", token);
    },
  },
};
</script>
